import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './style.css';

const ApexLegendsServerStatus = ({ currentUserEmail }) => {
    const [loader, setLoader] = useState(false);
    const [serverStatus, setServerStatus] = useState('');
    const [serverRegion, setServerRegion] = useState('EU-East');
    const [regions, setRegions] = useState([]);;

    const serverRegionRef = useRef();
    const auth = process.env.REACT_APP_APEX_API_KEY;

    async function GetServerStatus(){
        setLoader(true);
        await axios.get('https://api.mozambiquehe.re/servers', {
            params: {
                auth: auth
            }
        }).then((res) => {
            setRegions(Object.entries(res.data.EA_accounts).map((x) => x[0]));
            setServerStatus(res.data.EA_accounts[serverRegion].Status)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoader(false)
        });

    };

    function handleServerRegionChange() {
        setServerRegion(serverRegionRef.current.value);
    }

    useEffect(() => {
        GetServerStatus();
    }, [serverRegion]);

        return (
            <>
            {currentUserEmail && 
            <div className="serverStatusDiv">
                <select onChange={handleServerRegionChange} value={serverRegion} ref={serverRegionRef}>
                    {regions && regions.map((region) => (
                      <option key={region} value={region}>{region}</option>
                    ))}
                </select>
                {loader ? <div>Loading...</div> : <div>Server Status: <span style={{ color: serverStatus === 'UP' ? 'green' : 'red', fontWeight: 'bold' }}>{serverStatus}</span></div>}
                
                <div className="smallLink"><a href="https://apexlegendsstatus.com" target="_blank" rel="noreferrer">(apexlegendsstatus.com)</a></div>
            </div>
            } 
            </>
        )
    
}

export default ApexLegendsServerStatus;


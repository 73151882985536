import React, { useContext, useEffect, useState, useRef } from 'react'
import { Button } from 'react-bootstrap';
import { StoreContext } from '../../App';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { FetchApexAPI } from '../../util/FetchApexAPI';
import firebase from 'firebase';
import './style.css';

const SearchUserName = observer(() => {
    const store = useContext(StoreContext);
    const [platform, setPlatform] = useState('PS4');
    const [loading, setLoading] = useState(false);
    const [errorUserName, setErrorUserName] = useState();
    const userNameEARef = useRef();
    const db = firebase.database();

    async function handleSubmitGameDetails (e) {
        e.preventDefault();
        setLoading(true);
    
        let isValidUserName;
    
        const res = await FetchApexAPI(platform, userNameEARef.current.value, 'https://api.mozambiquehe.re/bridge')
        .catch((err) => console.log(err));
    
        res ? isValidUserName = true : isValidUserName = false;
       
        if(isValidUserName) {
            setErrorUserName('');
            try{
                db.ref('Picked/' + store.currentUser.uid).update({
                    EAUserName: userNameEARef.current.value,
                    Platform: platform
                });
                
                
                runInAction(() => {
                    store.userNameEA = userNameEARef.current.value;
                    store.platform = platform;
                });
    
            } catch(err) {
                alert(err);
    
            } finally {
                setLoading(false);
            }
        }
    
        else {
            setErrorUserName('Seems like the username is invalid');
            userNameEARef.current.focus();
            setLoading(false);
        }
    }
    
    useEffect(() => {
    
    },[platform]);

    return (
        <div className="SearchUserDiv">
             <h3>Get Player Stats:</h3> 
            <form onSubmit={handleSubmitGameDetails} >
                <input placeholder="Insert Apex Legends User Name" type="text" ref={userNameEARef} required />
                <label>Platform: </label>
                <select defaultValue="PS4" onChange={(e) => setPlatform(e.target.value)}>
                    <option value="PS4">PS / Xbox</option>
                    <option value="PC">PC</option>
                </select>
                <Button disabled={loading} className="w-15 mt-1" type="submit">Search</Button>
            </form>
            {errorUserName && <div style={{ flexGrow: '1', flexBasis: '100%' }}><h6 style={{ color: 'red', textAlign: 'center', marginTop: '5px' }}>{errorUserName}</h6></div>}
        </div>
    );
});

export default SearchUserName;

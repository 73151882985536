import React, { useState, useEffect, useContext } from 'react'
import firebase from 'firebase';
import _ from 'lodash';
// import { GenerateUsers } from '../../GenerateUsers'
import './style.css';
import SelectWeapons from './components/SelectWeapons';
import CharacterCard from '../CharacterCard/CharacterCard';
import SelectDropZone from './components/SelectDropZone';
import { StoreContext } from '../../App';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { FetchApexAPI } from '../../util/FetchApexAPI';
import { Spinner } from 'react-bootstrap';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

const characterLabels = [];
const weaponLabels = [];



const Stats = observer(() => {
    const store = useContext(StoreContext);
    const db = firebase.database();
    const [pickedCharacterData, setPickedCharacterData] = useState();
    const [pickedPWeaponData, setPickedPWeaponData] = useState();
    const [pickedSWeaponData, setPickedSWeaponData] = useState();
    const [pickedDropZoneData, setPickedDropZoneData] = useState();
    const [characters, setCharacters] = useState();
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState();

    
      

     const dataMainLegend = {
      characterLabels,
        datasets: [
          {
            label: 'Main Legends By Users',
            data: pickedCharacterData,
                backgroundColor: '#ca3637cc',
          }
        ],
      };
      
      const dataMainPWeapon = {
        weaponLabels,
        datasets: [
          {
            label: 'Main Primary Weapon',
            data: pickedPWeaponData,
                backgroundColor: 'green',
          }
        ],
      };

      const dataMainSWeapon = {
        weaponLabels,
        datasets: [
          {
            label: 'Main Secondary Weapon',
            data: pickedSWeaponData,
                backgroundColor: 'blue',
          }
        ],
      };

      const dataDropZone = {
        weaponLabels,
        datasets: [
          {
            label: 'Drop Zone',
            data: pickedDropZoneData,
                backgroundColor: 'yellow',
          }
        ],
      };

      function handleChooseCharacter(character) {
        try{
            db.ref('Picked/' + store.currentUser.uid).update({
                PickedCharacter: character
            });

            runInAction(() => {
                store.pickedMainLegend = character;
            });
        } catch(err) {
            console.log(err);
        }
    }


    useEffect(() => {
        db.ref().child('Picked').get().then((snapshot) => {
            let picksPerCharacter = [];
            let picksPerPWeapon = [];
            let picksPerSWeapon = [];
            let picksPerDropZone = [];

            snapshot.forEach((childSnapshot) => {
                if(childSnapshot.val().PickedCharacter) picksPerCharacter.push(childSnapshot.val().PickedCharacter);
                if(childSnapshot.val().PrimaryWeapon) picksPerPWeapon.push(childSnapshot.val().PrimaryWeapon);
                if(childSnapshot.val().SecondaryWeapon) picksPerSWeapon.push(childSnapshot.val().SecondaryWeapon);
                if(childSnapshot.val().DropZone) picksPerDropZone.push(childSnapshot.val().DropZone);
            })

            const countedDataCharacter = _.countBy(picksPerCharacter);
            const countedDataPWeapon = _.countBy(picksPerPWeapon);
            const countedDataSWeapon = _.countBy(picksPerSWeapon);
            const countedDropZone = _.countBy(picksPerDropZone);

            setPickedCharacterData(countedDataCharacter);
            setPickedPWeaponData(countedDataPWeapon);
            setPickedSWeaponData(countedDataSWeapon);
            setPickedDropZoneData(countedDropZone);
        });
        
    },[])

    useEffect(() => {
      FetchApexAPI('PS4', 'orazoulay1986', 'https://api.mozambiquehe.re/bridge')
      .then((res) => {
        setLoader(true);
        if (res.data.Error) {
          //Error returns as a success for some reason so we need to check if the error returned as text, mostly because the user is invalid
          setError(res.data.Error);
        } else {
          setCharacters(res.data.legends.all);
        }
      })
      .catch((err) => setError(err))
      .finally(() => setLoader(false))
    },[]);

    return (
        <div className="statsDiv">
            {!store.pickedMainLegend && !characters && loader && <Spinner animation="grow" style={{ display: 'flex', margin: 'auto', marginTop: '10px' }} /> }
            {   
                !store.pickedMainLegend && characters && !error &&
                <div className="characterChardList">
                    <div className="legendSelectTitle"><h3>Choose Your Main Legend</h3></div>
                    {Object.entries(characters).map((legend) => (
                       <CharacterCard 
                            character={legend[0]} 
                            info={legend[0]} 
                            img={legend[1].ImgAssets.icon} 
                            key={legend[0]} 
                            handleChooseCharacter={handleChooseCharacter} 
                        />
                    ))}
                  <hr/>
                </div> 
            }
            
          { store.pickedMainLegend && <Bar options={options} data={dataMainLegend} /> }
          { store.pickedMainLegend &&
              <div className="rowDiv removeable" onClick={() => handleChooseCharacter(null)}>
                  <div className="title">Your Picked Main Legend</div>
                  <div>{store.pickedMainLegend}</div>
                  <div>
                    { characters && <img className="selectedLegendImg"  src={characters[store.pickedMainLegend].ImgAssets.icon} alt="Main Legend"/> }
                  </div>
              </div>
            }<hr />
            {!store.PrimaryWeapon ? <SelectWeapons name="PrimaryWeapon" /> : <Bar options={options} data={dataMainPWeapon} /> }<hr/>
            {!store.SecondaryWeapon ? <SelectWeapons name="SecondaryWeapon" /> : <Bar options={options} data={dataMainSWeapon} /> }<hr/>
            {!store.DropZone ? <SelectDropZone /> : <Bar options={options} data={dataDropZone} /> }
            {/* <button onClick={GenerateUsers} >test===</button> */}
            
        </div>
    );
});

export default Stats

import axios from 'axios';

export const FetchApexAPI = async (platform, eausername, url) => {

    const auth = process.env.REACT_APP_APEX_API_KEY;

    const res = await axios.get(url, {
        params: {
            platform: platform,
            auth: auth,
            player: eausername
        }
    });
    
    return res;
}
import React, { useContext } from 'react'
import { StoreContext } from '../../App';
import { observer } from 'mobx-react';
import { Spinner } from 'react-bootstrap';
import './style.css';

    const PlayerData = observer(({ playerData, loader, error }) => {
    const store = useContext(StoreContext);
    
    return (
        <>
            {loader && <Spinner animation="grow" />}
            {error && <div>Something went wrong :( <br/>Error: {error}</div>}
            {
                playerData && !error && !loader &&
                <>
                    <div className="playerDataDiv">
                        <h1>{store.userNameEA.toUpperCase()}</h1>
                        <div className="rowDiv">
                            <div className="title">Your Rank</div>
                            <div><img src={playerData.global.rank.rankImg} alt={playerData.global.rank} /></div>
                        </div>
                        <div className="rowDiv">
                            <div className="title">Player is Currently {playerData.realtime.isOnline ? <span style={{ color: 'green' }}>Online</span> : <span style={{ color: 'red' }}>Offline</span>}</div>
                        </div>
                        <div className="rowDiv">
                            <div className="title">Current Selected Legend</div>
                            <div>{playerData.realtime.selectedLegend}</div>
                            <div>
                                <img className="selectedLegendImg" src={playerData.legends.selected.ImgAssets.icon} alt="Selected Legend" />
                            </div>
                            <div className="moreLegendDataDiv">
                                <div>Kills: {playerData.legends.selected.data.length ? playerData.legends.selected.data[0].value : ''}</div>
                                <div>Skin: {playerData.legends.selected.gameInfo.skin}</div>
                                <div>Rarity: {playerData.legends.selected.gameInfo.skinRarity}</div>
                                <div>Intro Quote: <br/> "{playerData.legends.selected.gameInfo.intro}"</div>
                            </div>
                            <div className="badgesDiv">
                                <h4>Frame Badges</h4>
                                <div className="badgeList">
                                    <div>{playerData.legends.selected.gameInfo.badges[0].name}</div> 
                                    <div>{playerData.legends.selected.gameInfo.badges[1].name}</div> 
                                    <div>{playerData.legends.selected.gameInfo.badges[2].name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="rowDiv">
                            <div className="title">Your REAL Level (Limited to 500 in-game)</div>
                            <div>{playerData.global.level}</div>
                        </div>
                    </div>
                </>
            }
        </>
    );

});

export default PlayerData

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';

 export const Index = () => {

    return (
        <Router>
            <AuthProvider>
                <App  />
            </AuthProvider>
        </Router>
    )
}

ReactDOM.render (
    
        
 <Index />
    
, document.querySelector("#root"));

import React, {useState, useEffect} from 'react'
import axios from 'axios';
import './style.css';

const News = () => {

    const [news, setNews] = useState();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const getNews = async () => { 
            await axios.get('https://api.mozambiquehe.re/news?lang=en-us&auth=RrFwWS74cQRIpLScIBi3')
            .then((res) => setNews(res.data))
            .catch((err) => console.log(err))
            .finally(() => setLoader(false));
        }
        getNews();

      
    },[]);

    const cards = document.querySelectorAll(".newsCard");

    const observer = new IntersectionObserver(entries => {
        entries.forEach((entry) => {
            entry.target.classList.toggle("show", entry.isIntersecting);  
            if(entry.isIntersecting) observer.unobserve(entry.target);    
        });
    }, { threshold: 0.5 });

    cards.forEach(card => {
        observer.observe(card);
    });

    return (
        <>
        <div className="newsCardContainer">
            {loader && <h3>Loading...</h3>}
            {news && news.map((item) => (
                <div className="newsCard" key={item.img}>
                    <div><h2>{item.title}</h2></div>
                    <div><h5>{item.short_desc}</h5></div>
                    <a href={item.link} target="blank"><img src={item.img} alt={item.img} /> </a>
                </div>
            ))}
        </div>
        </>
    )
}

export default News;

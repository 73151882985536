import React, { useState, useEffect } from 'react'
import { FetchApexAPI } from '../../util/FetchApexAPI';
import Countdown from 'react-countdown';
import './style.css';

const MapRotation = () => {
    const [data, setData] = useState();

    const getMapRotation = () => {
        //Setting timeout because the API is limited to 3 requests per second
        setTimeout(async () => {
            const res = await FetchApexAPI(null, null, 'https://api.mozambiquehe.re/maprotation?version=2')
            setData(res.data);
        },1000);
    }
    
    useEffect(() => {
        getMapRotation();
    }, [])

    return (
        <>
        {
            data && 
            <div className="mapRotation">
                <h4>Map Rotation</h4>
                <h6>Current Map: {data.battle_royale.current.map}</h6>
                <h6>Time Left: <Countdown date={Date.now() + data.battle_royale.current.remainingSecs * 1000} /></h6>
            </div>
            
        }
        </>
    )
}

export default MapRotation;

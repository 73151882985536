import React, { useState, useContext, useEffect } from 'react';
import PlayerData from '../PlayerData/PlayerData';
import MapRotation from '../MapRotation/MapRotation';
import { FetchApexAPI } from '../../util/FetchApexAPI';
import './style.css';
import { StoreContext } from '../../App';
import { observer } from 'mobx-react';
import SearchUserName from '../SearchUserName/SearchUserName';
import { useAuth } from '../../contexts/AuthContext';

const Home = observer(() => {
    const store = useContext(StoreContext);
    const [playerData, setPlayerData] = useState();
    const [error, setError] = useState();
    const [loader, setLoader] = useState();
    const { currentUser } = useAuth();

    store.currentUser = currentUser;

    useEffect(() => {
        if(store.platform) {
            setLoader(true);
            FetchApexAPI(store.platform, store.userNameEA, 'https://api.mozambiquehe.re/bridge')
            .then((res) => {
                if(res.data.Error) {
                    //Error returns as a success for some reason so we need to check if the error returned as text, mostly because the user is invalid
                    setError(res.data.Error);
                }
                else {
                    setPlayerData(res.data);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoader(false))
        }
    },[store.userNameEA]);
    console.log(store)
    return  (
        <div className="home">
            <MapRotation />
            <SearchUserName />
            {
                store.userNameEA && 
                <PlayerData
                    playerData={playerData}
                    loader={loader}
                    error={error} 
                />
            }
        </div>
    );
});

export default Home;
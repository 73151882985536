import React, { useContext } from 'react'
import { StoreContext } from '../../../App';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import firebase from 'firebase';
import './style.css';
const SelectWeapons = observer(({ name }) => {
    const store = useContext(StoreContext);
    console.log(store)
    const db = firebase.database();

    const weapons = ["VK-47 Flatline", "M600 Spitfire", "R-301 Carbine", "Kraber .50-Cal Sniper", "Eva-8 Auto", "C.A.R. SMG", "Wingman", "Alternator", "R-99 Light SMG", "Sentinel", "Volt energy SMG", "G7 Scout", "Prowler Burst PDW", "Rampage LMG", "Bocek Compound Bow", "Peacekeeper", "Triple Take", "Hemlock","Charge Rifle","Longbow DMR","Mastiff","30-30 Repeater","Devotion Energy LMG","P2020","RE-45","HAVOC","Mozambique"].sort();
    
    function handleChange(e) {
        try{
            db.ref('Picked/' + store.currentUser.uid).update({
                [e.target.name]: e.target.value
            });

            runInAction(() => {
                store[e.target.name] = e.target.value;
            });
            
        } catch(err) {
            console.log(err);
        }
    }

    return (
        <div className="weaponsDropdownDiv">
            <label>Choose Your { name === 'PrimaryWeapon' ? 'Primary' : 'Secondary' } Weapon:</label>
            <select name={name} onChange={(e) => handleChange(e)} value={store.PrimaryWeapon}>
                {weapons.map((weapon) => (
                    <option key={weapon} value={weapon}>{weapon}</option>
                ))}
            </select>
        </div>
    );
});

export default SelectWeapons;

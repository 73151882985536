import React from 'react';
import './style.css';

const CharacterCard = ({ character, img, handleChooseCharacter }) => {
    
    return ( 
        
        <div className="charCard" key={character} onClick={() => handleChooseCharacter(character)} id={character} >
            <div>
                <img src={img} alt={character} />
            </div>
            <div className="charTitle">{character}</div>
        </div>
    )
}


export default CharacterCard;
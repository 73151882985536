import React, { useState } from 'react';
import './index.css';
import { Signup, Login ,ForgotPassword, UpdateProfile } from './components/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch, useHistory} from 'react-router-dom';
import  PrivateRoute from './util/PrivateRoute';
import News from './components/News/News';
import Header from './components/Header/Header';
import Stats from './components/Stats/Stats';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import { useAuth } from './contexts/AuthContext';
import { useLocalObservable } from 'mobx-react';
import firebase from 'firebase';
import { runInAction } from 'mobx';

export const StoreContext = React.createContext();


const StoreProvider = (({ children }) => {
    const { currentUser } = useAuth();
    const db = firebase.database();
   
    const store = useLocalObservable(() => ({
        currentUser: currentUser,
        userNameEA: '',
        platform: '',
        pickedMainLegend: '',
        PrimaryWeapon: '',
        SecondaryWeapon: '',
        DropZone: ''
    }));

    
    if(currentUser) {
        (async function GetDataFromDb() {
            
            await db.ref().child('Picked').child(currentUser.uid).get().then((snapshot) => {
                if(snapshot.exists()){
                    runInAction(() => {
                        store.pickedMainLegend = snapshot.val().PickedCharacter;
                        store.PrimaryWeapon = snapshot.val().PrimaryWeapon;
                        store.SecondaryWeapon = snapshot.val().SecondaryWeapon;
                        store.DropZone = snapshot.val().DropZone;
                    }) 
                }
            }).catch((error) => {
               
                console.log(error);
            });

        })();
    }
    
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    )
});

function App() {
    const { currentUser, logout } = useAuth();
    const history = useHistory();
    const [error, setError] = useState();
    
    async function handleLogout() {
        try {
            await logout();
            history.push('/login')
        }
        catch(error) {
            setError('Error logging in! ' + error);
        }
    }

    return (
            <StoreProvider>
                <div className="main">
                    {error && <div>{error}</div>}
                    <Header currentUser={currentUser} handleLogout={handleLogout}/>
                    <Switch>
                        <PrivateRoute exact path="/" component={Home} />
                        <PrivateRoute path="/update-profile" component={UpdateProfile} />
                        <PrivateRoute path="/news" component={News} />
                        <PrivateRoute path="/stats" component={Stats} />
                        <Route path="/signup" component={Signup} />
                        <Route path="/login" component={Login} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                    </Switch>
                    <Footer />            
                </div>
            </StoreProvider>
    )

 
}

export default App;

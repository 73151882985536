import React, {useContext} from 'react';
import ApexLegendsServerStatus from '../ApexLegendsServerStatus/ApexLegendsServerStatus';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './style.css';
import { StoreContext } from '../../App';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react'

const Header = ({currentUser, handleLogout}) => {
    const store = useContext(StoreContext);

    function ResetStore() {
        
        store.currentUser = ''
        store.userNameEA = ''
        store.platform = ''
        store.pickedMainLegend = ''
        store.PrimaryWeapon = ''
        store.SecondaryWeapon = ''
        
        handleLogout();
    }

    const renderHelper = () => {
        if(currentUser){
            return (
                <>
                    <nav className="navDiv">
                        <div><Link className="link" to="/">HOME</Link></div> 
                        <div><Link className="link" to="/stats">STATS</Link></div>
                        <div><Link className="link" to="/news">NEWS</Link></div>
                    </nav>
                    <ApexLegendsServerStatus currentUserEmail={currentUser.email} />
                    <div className="loggingButtonsDiv">
                        <div className="loggenInEmailLabel">Logged In as: {currentUser.email}</div>
                        <div><Link className="btn btn-primary" to="/update-profile">Edit Profile</Link></div>
                        <div><Link className="btn btn-danger" to="" onClick={() => { ResetStore() }}>Log Out</Link></div>
                    </div>
                </>
            )
        }
    }

    return (
        <div className="header">
            <img src={logo} style={{ width: '70px', height: '50px' }} alt="logo"/>
            {renderHelper()}
        </div>

    )
}

export default Header;
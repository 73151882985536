import React from 'react';
import './style.css';

const Footer = () => {
    return (
        <div className="footer">
            <div>Footer</div>
        </div>

    )
}

export default Footer;
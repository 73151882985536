import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../../App';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import firebase from 'firebase';
import './style.css';

const SelectDropZone = observer(() => {
    const store = useContext(StoreContext);
    const db = firebase.database();

    useEffect(() => {
        const areas = document.querySelectorAll("area");
        areas.forEach((area) => {
            area.addEventListener("click", (e) => {
            console.log(e.target.title);
                try{
                    db.ref('Picked/' + store.currentUser.uid).update({
                        "DropZone": e.target.title
                    });

                    runInAction(() => {
                        store.DropZone = e.target.title;
                    });
                    
                } catch(err) {
                    console.log(err);
                }
            });
        });

    },[])

    return (
        <div>
            <img src="https://cdn1.dotesports.com/wp-content/uploads/2021/10/26123456/storm-point-mini-map-rodney.png" useMap="#image-map" /> 

            <map name="image-map">
                <area alt="barometer" title="barometer" coords="346,744,60" shape="circle" />
                <area alt="ship-fall" title="ship-fall" coords="499,910,76" shape="circle" />
                <area alt="gale-station" title="gale-station" coords="658,829,65" shape="circle" />
                <area alt="fish-farms" title="fish-farms" coords="833,817,111" shape="circle" />
                <area alt="launch-pad" title="launch-pad" coords="774,635,74" shape="circle" />
                <area alt="antena" title="antena" coords="586,693,79" shape="circle" />
                <area alt="the-mill" title="the-mill" coords="155,477,63" shape="circle" />
                <area alt="checkpoint" title="checkpoint" coords="314,333,76" shape="circle" />
                <area alt="cascade-falls" title="cascade-falls" coords="493,389,64" shape="circle" />
                <area alt="storm-catcher" title="storm-catcher" coords="755,427,87" shape="circle" />
                <area alt="command-center" title="command-center" coords="656,299,73" shape="circle" />
                <area alt="north-pad" title="north-pad" coords="262,180,78" shape="circle" />
                <area alt="the-wall" title="the-wall" coords="506,160,95" shape="circle" />
                <area alt="cenote-cave" title="cenote-cave" coords="180,720,91" shape="circle" />
                <area alt="highpoint" title="highpoint" coords="757,105,70" shape="circle" />
                <area alt="thunder-watch" title="thunder-watch" coords="803,265,61" shape="circle" />
                <area alt="lightning-rod" title="lightning-rod" coords="891,163,70" shape="circle" />
            </map>
        </div>
    )
});

export default SelectDropZone;
